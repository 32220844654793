const majorsList = [
  { id: '국어국문학전공', name: '국어국문학전공' },
  { id: '국제한국어교육전공', name: '국제한국어교육전공' },
  { id: '미디어문예창작전공', name: '미디어문예창작전공' },
  { id: '역사문화학과', name: '역사문화학과' },
  { id: '프랑스언어·문화학과', name: '프랑스언어·문화학과' },
  { id: '중어학전공', name: '중어학전공' },
  { id: '중문학전공', name: '중문학전공' },
  { id: '통번역전공', name: '통번역전공' },
  { id: '독일언어·문화학과', name: '독일언어·문화학과' },
  { id: '일본학과', name: '일본학과' },
  { id: '문헌정보학과', name: '문헌정보학과' },
  { id: '교육경영전공', name: '교육경영전공' },
  { id: '교수학습자전공', name: '교수학습자전공' },
  { id: '문화관광학전공', name: '문화관광학전공' },
  { id: '르 꼬르동 블루 외식경영전공', name: '르 꼬르동 블루 외식경영전공' },
  { id: '화학과', name: '화학과' },
  { id: '생명시스템학부', name: '생명시스템학부' },
  { id: '수학과', name: '수학과' },
  { id: '통계학과', name: '통계학과' },
  { id: '체육교육과', name: '체육교육과' },
  { id: '한국무용전공', name: '한국무용전공' },
  { id: '발레전공', name: '발레전공' },
  { id: '현대무용전공', name: '현대무용전공' },
  { id: '기계시스템학부', name: '기계시스템학부' },
  { id: '화공생명공학부', name: '화공생명공학부' },
  { id: '기초공학부', name: '기초공학부' },
  { id: '컴퓨터과학전공', name: '컴퓨터과학전공' },
  { id: 'SW융합전공', name: 'SW융합전공' },
  { id: 'IT공학전공', name: 'IT공학전공' },
  { id: '전자공학전공', name: '전자공학전공' },
  { id: '응용물리전공', name: '응용물리전공' },
  { id: '가족자원경영학과', name: '가족자원경영학과' },
  { id: '아동*청소년 복지전공', name: '아동*청소년 복지전공' },
  { id: '아동보육*교육전공', name: '아동보육*교육전공' },
  { id: '아동 심리상담전공', name: '아동 심리상담전공' },
  { id: '의류학과', name: '의류학과' },
  { id: '식품영양학과', name: '식품영양학과' },
  { id: '정치외교학과', name: '정치외교학과' },
  { id: '행정학과', name: '행정학과' },
  { id: '홍보광고학과', name: '홍보광고학과' },
  { id: '소비자경제학과', name: '소비자경제학과' },
  { id: '사회심리학과', name: '사회심리학과' },
  { id: '법학부', name: '법학부' },
  { id: '경영학부', name: '경영학부' },
  { id: '경제학부', name: '경제학부' },
  { id: '피아노과', name: '피아노과' },
  { id: '관현악과', name: '관현악과' },
  { id: '성악과', name: '성악과' },
  { id: '작곡과', name: '작곡과' },
  { id: '약학부', name: '약학부' },
  { id: '시각·영상디자인과', name: '시각·영상디자인과' },
  { id: '산업디자인과', name: '산업디자인과' },
  { id: '건축전공', name: '건축전공' },
  { id: '공간환경디자인전공', name: '공간환경디자인전공' },
  { id: '공예과', name: '공예과' },
  { id: '회화과', name: '회화과' },
  { id: '글로벌융합학부', name: '글로벌융합학부' },
  { id: '글로벌협력전공', name: '글로벌협력전공' },
  { id: '앙트러프러너십전공', name: '앙트러프러너십전공' },
  { id: '영어영문학전공', name: '영어영문학전공' },
  { id: '테슬전공', name: '테슬전공' },
  { id: '엔터테인먼트 미디어전공', name: '엔터테인먼트 미디어전공' },
  { id: '미디어 저널리즘전공', name: '미디어 저널리즘전공' },
  { id: '영상 콘텐츠전공', name: '영상 콘텐츠전공' },
];

export const MAJORS = Object.freeze(
  majorsList.slice(0).sort(function (a, b) {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  })
);
